import "./About.css";
import pr1 from "../../images/prechistenko5.jpg";
import pr2 from "../../images/prechistenko3.jpg";
import pr3 from "../../images/prechistenko1.jpeg";
import pr4 from "../../images/prechistenko2.jpg";

export default function About() {
  return (
    <section className="about page__size">
      <div className="about__container">
        <div className="about__block">
          <img
            src={pr1}
            alt="ПРЕЧИСТЕНКА вид с улицы на особняк"
            title="ПРЕЧИСТЕНКА вид с улицы на особняк"
            className="about__img about__img-left image__size"
          />
           <div className="about__block-title">
             <h1 className="about__title">ПРЕЧИСТЕНКА</h1>
          <p className="about__text-title">
          - одна из
            старинных московских улиц, а также одна из самых красивых и
            роскошных, хранящих воспоминания о знаменитых аристократах,
            богатейших коммерсантах, великих писателях и поэтах, в разное время
            живших здесь, относится к району "золотой мили".
          </p>
          </div>
        </div>
        <div className="about__block">
          <img
            src={pr2}
            alt="ПРЕЧИСТЕНКА вид с улицы на особняк"
            title="ПРЕЧИСТЕНКА вид с улицы на особняк"
            className="about__img about__img-left image__size"
          />
          <div className="about__block-text">
           <>
              <p className="about__text-fat" style={{ whiteSpace: "pre-line" }}>
                «Коллекция квартир в особняке» <br /> ул. Пречистенка 33/19,с2
              </p>
            </>
            <ul className="about__text">
              <li className="about__text-item">
                <span className="about__text-text"> Количество лотов:</span> 10
              </li>
              <li className="about__text-item">
                <span className="about__text-text"> Назначение:</span>
                квартира/апартаменты
              </li>
              <li className="about__text-item">
                <span className="about__text-text">Площадь:</span>
                74,4 - 157,8 м2
              </li>
              <li className="about__text-item">
                <span className="about__text-text">Высота потолка:</span> 3,2 -
                5,5 м2
              </li>
              <li className="about__text-item">
                <span className="about__text-text">Отделка:</span> без отделки
              </li>
              <li className="about__text-item">
                <span className="about__text-text">Цена:</span> 59,5 - 220,9
                млн.р.
              </li>
            </ul>
          </div>
        </div>
        <div className="about__block about__block-line">
          <p className="about__text">
            Прекрасный особняк с историческими винными погребами и сводчатыми
            потолками, хранит в себе особую атмосферу того времени. Расположен
            на закрытой охраняемой территории, оснащён премиальной инновационной
            инженерией и коммуникациями : радиаторы производства IRSAP (Италия),
            материал стояков нержавеющая сталь KAN-Therm Inox (Польша),
            установлена система защиты от протечек Gidrolock, система
            приточно-вытяжной вентиляции с рекупирацией воздуха RUCK (Германия),
            система кондиционирования AERMEC (Италия), окна производства DOLETA
            (Литва); отделка внутренней части подъездов: пол травертин.
          </p>
          <img
            src={pr3}
            alt="ПРЕЧИСТЕНКА вид с улицы на особняк"
            title="ПРЕЧИСТЕНКА вид с улицы на особняк"
            className="about__img about__img-right image__size"
          />
        </div>
        <div className="about__block">
          <div className="about__block-text">
            <p className="about__text-pr">
              Просторные светлые квартиры с большим количеством окон, высокими
              потолками, видом во двор и на улицу Пречистенка. Со стороны
              внутренний части особняка уютно расположился "секретный дворик». В
              части помещений подвала предусмотрены келлеры различной площади.
            </p>
            <p className="about__text-pr">
              <span className="about__text-fat">Особое внимание</span> обращает на себя квартира «эксклюзивной планировки» с
              частично-вторым уровнем в виде антресоли и помещением для хранения на индивидуальном чердаке.
            </p>
          </div>
          <img
            src={pr4}
            alt="ПРЕЧИСТЕНКА вид с улицы на особняк"
            title="ПРЕЧИСТЕНКА вид с улицы на особняк"
            className="about__img about__img-right image__size"
          />
        </div>
      </div>
    </section>
  );
}
