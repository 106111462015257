import "./Pictures.css";
import pic1 from '../../images/pr6.jpeg'
import pic2 from '../../images/fasad.jpg'
import pic3 from '../../images/prechistenko8.jpg'
import pic4 from '../../images/prechistenko4.jpg'

export default function Pictures() {
  return (
    <section className="pictures page__size">
      <div className="pictures__container">
        <figure className="pictures__card">
          <img
            src={pic1}
            alt="лестничный проём"
            className="pictures__img image__size"
          />
        </figure>
        <figure className="pictures__card">
          <img
            src={pic2}
            alt="Фасад 1 (Историческая часть )."
            className="pictures__img image__size"
          />
          <figcaption className="pictures__sign">Фасад 1 (Историческая часть )</figcaption>
        </figure>
        <figure className="pictures__card">
          <img
            src={pic3}
            alt="Фасад 2 ( Неисторическая часть )"
            className="pictures__img image__size"
          />
                    <figcaption className="pictures__sign">Фасад 2 ( Неисторическая часть )</figcaption>
        </figure>
        <figure className="pictures__card">
          <img
            src={pic4}
            alt="комната"
            className="pictures__img image__size"
          />

        </figure>
      </div>
    </section>
  );
}
