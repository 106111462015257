
import "./Plates.css";

import React from 'react';

const Plates = ({ title, img, subtitle, details, images, text }) => {
  return (
  
        <div className="plates__obj">
          <div className="plates__block-left"> 
            <div className="plates__subtitle">
            <h2 className="plates__title">{title}</h2>
            <p className="plates__sub">{subtitle}</p>
            <p className="plates__sub">{text}</p>
            </div>
       
            <img src={img} alt={`план квартиры ${title}`} className="plates__img-plan image__size" />
    <ul className="plates__list">
              {details.map((item, index) => (
                <li key={index} className="plates__text">
                  <strong>{item.title}:</strong> {item.value}
                </li>
              ))}
            </ul> 
          </div>
       <div className="plates__block-right">
            {images.map((item, index) => (
              <img key={index} src={item.img} alt={item.alt} className="plates__img image__size" />
            ))}

          </div> 
       </div> 


  );
}
export default Plates;




