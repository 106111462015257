import { plateImage } from "../constants/plateImage";
import "./plateImage.css"

export default function PlateImage() {
    return (
      <section className="plateImage">
        {plateImage.map((value, index) => (
          <img className="plateImage__item image__size" src={value.img} alt={value.title} title={value.title} key={index} />
        ))}
      </section>
    );
  }