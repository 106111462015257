import img from "../../images/img1.jpg";
import img1 from "../../images/img2.jpg";
import img2 from "../../images/img3.jpg";
import img3 from "../../images/img4.jpg";

const plateImage = [
  {
    img: img,
    title: "изображение квартиры №10",
  },
  {
    img: img1,
    title: "изображение квартиры №10",
  },
  {
    img: img2,
    title: "изображение квартиры №10",
  },
  {
    img: img3,
    title: "изображение квартиры №10",
  },
];
export {plateImage}