import Dev from "../Develops/Develops"
import "./Footer.css"

export default function Footer(){

    return(
        <footer className="footer page__footer">
            <div className="footer__container">
                <div className="footer__logo"></div>
                <Dev />
            </div>
        </footer>
    )
}