
import Plates from "../Plates/Plates";
import { apartments, loftApartment } from "../constants/constPlates";
import PlateImage from "../plateImage/plateImage";

const PlateList = () => {
    return (
        <section className="plates page__size" id="plate">
        <div className="plates__container">
        {apartments.map((apartment, index) => (
          <Plates
            key={index}
            title={apartment.title}
            img={apartment.img}
            subtitle={apartment.subtitle}
            details={apartment.details}
            images={apartment.images}
          />
        ))}
        {loftApartment.map((apartment, index) => (
        <Plates
        key={index}
          title={apartment.title}
          img={apartment.img}
          details={apartment.details}
          images={apartment.images}
          subtitle={apartment.subtitle}
          text={apartment.text}
        />
    ))}
              <PlateImage />
      </div>

      </section>
    );
  };
  
  export default PlateList;